import React from "react";

class JobCard extends React.Component {
  isNew = () => {
    var created = new Date(this.props.created);

    var d = new Date();
    d.setDate(d.getDate() - 3);

    return created > d;
  };

  render() {
    return (
      <div className="card job-card text-white bg-dark ribbon-box">
        <div className="card-header text-info ">
          {this.props.company} <span className="text-muted">{this.props.timeAgo.format(new Date(this.props.created))}</span>{" "}
        </div>
        <div className="card-body">
          <div className="job-card-inner">
            <h4 className="card-title">{this.props.role}</h4>
            <p className="card-text job-card-text text-secondary">{this.props.skills ? this.props.skills.join(", ") : " "}</p>
          </div>
          <div className="text-right">
          <a href={this.props.url} target="_blank" rel="noopener noreferrer" className="stretched-link ">
              
            </a>
            {/* <a href={this.props.url} target="_blank" rel="noopener noreferrer" className="btn btn-primary btn-sm stretched-link ">
              Apply
            </a> */}
          </div>
        </div>
        {this.isNew() && <div className="ribbon">
          <span>NEW</span>
        </div>}
      </div>
    );
  }
}

export default JobCard;
