import axios from "axios";

const location = process.env.REACT_APP_LOCATION.toLocaleLowerCase()

const apiKey = "itjobslondon_1989";
const baseUrl = "https://itjobslondon.co.uk/api";
const jobsUrl = "/jobs";



export const getJobs = (id) => {
  let url = ""

  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // dev
    url = `http://${location}.devsjobs.com/files/${location}.json`
  } else {
    // prod
    url = `/files/${location}.json`
  }

  return createAxiosGet(url);
};

const createAxiosGet = (url) => {
  var axiosConfig = {
    method: "get",
    url,
    headers: { "Api-Key": apiKey }
  };

  return axios(axiosConfig);
};