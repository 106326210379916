import axios from "axios";

const url = "https://ipapi.co/latlong";



export const getLocation = async () => {
    var axiosConfig = {
        method: "get",
        url
      };
    
    const response = await axios(axiosConfig);

    let latlong = response.data.split(",");
    let latitude = parseFloat(latlong[0])
    let longitude = parseFloat(latlong[1])
    let nearestCity = findNearestCity(latitude, longitude)

    return nearestCity;
};

var cities = [
    {
        id: "austin",
        latitude: 30.267153,
        longitude: -97.743057
    },
    {
        id: "san-jose",
        latitude: 37.338207,
        longitude: -121.886330
    },
    {
        id: "sydney",
        latitude: -33.868820,
        longitude: 151.209290
    },
    //{
    //    id: "melbourne",
    //    latitude: -37.813629,
    //    longitude: 144.963058
    //},
    {
        id: "london",
        latitude: 51.507351,
        longitude: -0.127758
    }
  ];
  

// Convert Degress to Radians
function Deg2Rad(deg) {
    return deg * Math.PI / 180;
  }
  
  function PythagorasEquirectangular(lat1, lon1, lat2, lon2) {
    lat1 = Deg2Rad(lat1);
    lat2 = Deg2Rad(lat2);
    lon1 = Deg2Rad(lon1);
    lon2 = Deg2Rad(lon2);
    var R = 6371; // km
    var x = (lon2 - lon1) * Math.cos((lat1 + lat2) / 2);
    var y = (lat2 - lat1);
    var d = Math.sqrt(x * x + y * y) * R;
    return d;
  }

  function findNearestCity(latitude, longitude) {
    var minDif = 99999;
    var closest;
  
    for (let index = 0; index < cities.length; ++index) {
      var dif = PythagorasEquirectangular(latitude, longitude, cities[index].latitude, cities[index].longitude);
      if (dif < minDif) {
        closest = index;
        minDif = dif;
      }
    }
  
    return cities[closest].id;
  }
